.four-column-section { 
    &__title {
        margin-bottom: .5em;
    }

    &__content {
        display: flex;
        margin-left: -1.125em;
        margin-right: -1.125em;
        flex-wrap: wrap;

        @media (max-width: 1450px) { 
            margin-left: -.5em;
            margin-right: -.5em;
        }

        @media (max-width: 950px) {
            margin-left: -.75em;
            margin-right: -.75em;
        } 

        @media (max-width: 550px) {
            margin-left:0;
            margin-right: 0 ;
        }

        &__item {
            flex: 0 0 (1/4 * 100%);
            box-sizing: border-box;
            padding-left: 1.25em;
            padding-right: 1.25em;

            &.no-padding {
                padding: 0;
            }

            ul {
                margin-top: .75em;
            }

            &.no-margin ul {
                margin-top: 0;
            }

            li:not(:last-child) {
                margin-bottom: .325em;
                line-height: 1.25;
            } 

            @media (max-width: 1450px) { 
                padding-left: .5em;
                padding-right: .5em;
            } 
 
            
            @media (max-width: 1200px) { 
                padding-left: .75em;
                margin-bottom: 2em;
                flex-basis: 50%;
                padding-right: .75em;
            }
            
            @media (max-width: 800px) { 
                margin-bottom: 1.25em; 
            }

            @media (max-width: 550px) {
                flex-basis: 100%; 
                margin-bottom: 1.5em; 
                padding:0;
            }
        }
    }
}
