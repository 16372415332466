@import '~styles/utils/config';
@import '~styles/utils/grid';
 

.base {
    @include grid-col();
    
}
.max{
    @media(max-width: 1024px){
        width: 100% !important;
      }

}

