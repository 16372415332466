:root {
    --dark-red: #210016;
    --gray:rgb(235, 237, 242);
    --dark-gray:rgb(49, 51, 59);
    --gold: rgb(197, 175, 113);
    --red: rgb(247, 73, 63); 
    --green: #5c6742; 
    --light-green: #ebebe2; 

    --text-color: var(--dark-red);
    --font-family: 'Aften Screen', Helvetica, sans-serif;
} 