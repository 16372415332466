@import '~styles/utils/config';

.quote {  
    width: (10/12 * 100%);
    margin-top: $padding-xlg;
    margin-bottom: calc(2 * #{$padding-xlg});

    @media (max-width:800px) {
        width: auto;    
        margin-bottom: $padding-lg
    }
}