#coi-cookie {
    border-top: none !important;
    padding-top: 2em !important;
    padding-bottom: 1.5em !important;
    padding-left: 64px !important;
    padding-right: 64px !important;
    z-index: 2000 !important;
    box-shadow: 0 0 2em rgba(0,0,0, .1); 
    transition: transform .75s .5s !important;

    @media (max-width: (700px)) {
        padding-top: 1.5em !important;
        padding-bottom: 1em !important;
    }  

    @media (max-width: (1000px)) {
        padding-left: 32px !important;
        padding-right: 32px !important;
    }

    @media (max-width: (800px)) {
        padding-left: 28px !important;
        padding-right: 28px !important;
    }
    
    @media (max-width: (600px)) {
        padding-left: 20px !important;
        padding-right: 24px !important;
    } 

    * {
        cursor: none !important;
        font-size: 1em !important;
    }

    .coi-banner__branding {
        display: none !important;
    }

    .c-header {
        margin-bottom: .5em !important;
        font-size: 1.1em !important;
        font-weight: 400 !important;
        text-align: left !important;
        letter-spacing: -.01em !important;
    }

    .c-message {
        margin-bottom: 1.25em !important;
        max-width: 35em;
        font-size: .85em !important;
        line-height: 1.3;
        text-align: left !important;
    }

    #c-right, 
    #c-left {
        display: inline !important;
        width: auto !important;
        float: none !important;
    }

    #c-left {
        a {
            padding: .85em 1.5em !important;
            background-color: rgba(0,0,0, .1) !important;
            display: inline-block !important;
            font-size: .85em !important;
            text-transform: uppercase !important;
            letter-spacing: -.025em !important;
            text-decoration: none !important;
            transition: all .25s !important;
            line-height: 1 !important;

            &:hover {
                box-shadow: inset 0 -10em  1em rgba(#000, .1) !important;
            }
        }
    }

    .c-button {
        margin-top: 0 !important;
        margin-left: 1em !important;
        border-radius: 0 !important;
        background-color: #68822d !important;
        font-size: .85em !important;
        line-height: 1 !important;
        width: auto !important;
        text-transform: uppercase !important;
        padding: .85em 1.5em !important;
        margin-bottom: 0 !important;
        letter-spacing: -.025em !important;
        transition: all .25s !important;

        &:hover {
            box-shadow: inset 0 -100000em  1em rgba(#000, .1) !important;
        }
    }
}


.raw-text {
    line-height: 1.3;
    padding-bottom: 5em;
    min-height: 70vh;

    h2 {
        font-size: 1.6em;
        margin-top: 1.5em;
        margin-bottom: .5em;
    }

    h3 {
        font-size: 1.5em;
    }

    .CookieDeclarationHeader {
        display: none;
    } 

    p + p {
        margin-top: 1em;
    }

    p {
        max-width: 45em;
    }

    .coitable__declarationtype {
        margin-top: 2em;
        margin-bottom: 2em;
        border-radius: .15em;
        border-color: var(--dark-gray);
    }

    ul {
        margin-top: 1em;
        margin-bottom: 1em;

        li {

            list-style-type: disc;
            padding-left: 0;
            margin-left: 2em;
        }
    }
}