body {   
    font-size: 1.5em;
    margin: 0; 
    font-family: var(--font-family);
    font-weight: 400;
    line-height: 1.3;
    -webkit-text-size-adjust: 100%; // iOS on orientation change 
    color: var(--text-color);

    @media (max-width: 1300px) { 
        font-size: 1.35em;
    }

    @media (max-width: 900px) { 
        font-size: 1.25em;
    }

    @media (max-width: 700px) { 
        font-size: 1.125em;
    }
} 

.h1 { 
    font-size: unquote('min(max(48px, 7vw), 120px)');
    line-height: 1;
    font-weight: 400;
    letter-spacing: (-5/120) + em;
    margin-left: -.065em;

}

.h1b { 
    font-size: unquote('min(max(38px, 6vw), 90px)');
    line-height: 1;
    font-weight: 400;
    letter-spacing: (-5/120) + em;
    margin-left: -.065em;
}

.h2 { 
    //font-size: 1em;
    //text-transform: uppercase;
    line-height: 1;
    //font-weight: 600; 
    letter-spacing: 0;
    margin-bottom: .45em; 
    font-size: 40px;
}
.h2b { 
    font-size:1.2em; // 60/24 + em; 
    font-weight: 400;
    line-height: 1; 
    letter-spacing: -.02em; 
}
.h3 { 
    font-size:unquote('min(max(32px, 3vw), 60px)'); // 60/24 + em; 
    line-height: 1;
    font-weight: 400; 
    letter-spacing: -.02em; 
}

.text {
    &--small {
        font-size: 18/24 + em;
    }
}