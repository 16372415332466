.text-block {    
    &__title {
        //margin-bottom: .25em;

        letter-spacing: (-1/120) + em;

        @media (max-width: 850px) {
            margin-bottom: .3em; 
        } 
    }

    &__text {
        width: 50%;

        p + p {
            margin-top: 1em;
        }

        @media (max-width: 1100px) { 
            width: 70%;
        }

        @media (max-width: 850px) { 
            width: 85%;
        }

        @media (max-width: 750px) {  
            width: auto;
        }
    }

    &--right { 
        margin-left: 50%;

        @media (max-width: 1200px) { 
            margin-left: 40%;
        }

        @media (max-width: 1100px) { 
            margin-left: 25%;
        }

        @media (max-width: 850px) {  
            margin-left: 15%;
        }

        @media (max-width: 750px) {  
            margin-left: 0;  
        }

        .text-block__text {
            width: auto;
        }
    }
}

.count {
    text-align: right;
}

.count-position {
    position: absolute;
    bottom: 4.5em;
    right: 0;
    width: 50%;
    @media (max-width: 650px){
        position: relative;
        bottom: 0em;
        width: 100%;
    }
}
.row1 {

    display: flex;
    justify-content: space-between;

}
