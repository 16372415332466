// @import 'variables';
@import 'grid';
@import 'mixins';

$gutter: 30px;
$container-gutter-mobile: $gutter;
$container-gutter-tablet: 50px;
$container-gutter-desktop: 70px;

// grid
$grid-column-count: 12;
$grid-baseline: 16px;

// page dimensions
$page-width: 1290px;
$page-limit: $page-width + ($container-gutter-desktop * 2);

// Fonts
$font-family: 'Aften Screen', sans-serif;
$font-heading: 'Aften Screen', sans-serif;
$font-size: 24px;
$font-size-min: 13px;
$line-height: 1.5;

// Colors
$color-font: #210016;
$color-background: #fff;
$color-placeholder: #ddd;
$color-header: rgb(235, 237, 242);

$color-white: #FFF;
$color-grey: rgb(235, 237, 242);
$color-dark-grey: rgba(0, 0, 0, 0.6);
$color-gold: rgb(197, 175, 113);
$color-red: rgb(247, 73, 63);
$color-dark-red: #210016;

$transition: cubic-bezier(0.25, 1, 0.5, 1);

$padding-sm: 20px;
$padding-md: 28px;
$padding-lg: 28px;
$padding-xlg: 64px;

// Custom break point (Min)
$min-1800: 1800px;
$min-1440: 1440px;
$min-1280: 1280px;
$min-1200: 1200px;
$min-1080: 1080px;
$min-1024: 1024px;
$min-960: 960px;
$min-840: 840px;
$min-768: 768px;
$min-720: 720px;
$min-640: 640px;
$min-600: 600px;
$min-560: 560px;
$min-540: 540px;
$min-480: 480px;
$min-420: 420px;
$min-375: 375px;
$min-360: 360px;

// Custom break points (Max)
$max-1280: 1279px;
$max-1200: 1199px;
$max-1080: 1079px;
$max-1024: 1023px;
$max-960: 959px;
$max-840: 839px;
$max-768: 767px;
$max-720: 719px;
$max-640: 639px;
$max-600: 599px;
$max-540: 539px;
$max-480: 479px;
$max-420: 419px;
$max-375: 374px;
$max-360: 359px;

// Predefined breakpoints
$bp-sm: $min-420;
$bp-md: $min-720;
$bp-lg: $min-1080;

// Segment
$segment-padding-mobile: $gutter * 3;
$segment-padding: 200px; //percentage-based segments spacing

// Vertical scaling values.
// remove this map to disable vertical scaling
// value 1 = height breakpoint, value 2 = multiplier
//$scale: ((920px, 0.975), (800px, 0.95), (690px, 0.925));

