@import '~styles/utils/config';

.page {

    @media (min-width: $bp-md) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        will-change: transform;
    }

    &__container {
        padding-top: 7em;
    }
}
