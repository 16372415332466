@import '~styles/utils/config';

.link {
    position: relative;
    &--text {
        display: inline-block;
        position: relative;
        transition: color .2s;

        span {
            position: relative;
            z-index: 1;
        }

        &::before {
            content: "";
            display: block;
            position: absolute; 
            top:0;
            bottom:0;  
            width: 0;
            left:-.25em; 
            transition: all .4s; 
            background-color: #000;
            mix-blend-mode: difference;
            z-index: 0;
        }

        &:hover,
        &.link--touching {
            color: #FFF;

            &::before { 
                width: calc(100% + .5em);
            }
        }
    }

    .header__navigation & {

        &:before {
            content: '';
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            background-color: #fff;
            transform: scaleX(0);
            transform-origin: 0 0;
            transition: transform 1000ms $transition;
        }

        &--active {
            position: relative;
            &:before {
                transform: scaleX(1);
            }
        }
    }
}