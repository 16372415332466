.contact {
    &__other-offices {
        display: flex;
        flex-wrap: wrap;
        margin-left: 50%;

        @media (max-width:1500px ) { 
            display: block;
        }   

        @media (max-width:1200px ) { 
            margin-left: 40%;
        }        
        
        @media (max-width:1100px ) { 
            margin-left: 25%;
        }

        &__title {
            flex: 0 0 100%; 
            margin-bottom: .25em; 
        }

        &__list {
            flex: 0 0 50%;
            padding-left: 1em;

            @media (max-width:1500px ) { 
                margin-top: 1em;
                padding-left: 0;
            }  
        }
        &__p {
            margin-top: 0;
            flex: 0 0 50%;
            opacity: .6;
        }
    }

    &__careers {
        display: flex;
        flex-wrap: wrap;
        margin-right: 50%;
        margin-top: 5em;

        @media (max-width:1500px ) { 
            display: block;
        }   

        @media (max-width:1200px ) { 
            margin-right: 40%;
            margin-top: 4em;
        }        
        
        @media (max-width:1100px ) { 
            margin-right: 25%;
        }

        @media (max-width: 800px ) { 
            margin-top: 2em;
        }

        &__title {
            flex: 0 0 100%; 
            margin-top: 3em; 
            margin-bottom: .25em; 
        }
        
        &__p {
            margin-bottom: 1em;
            flex: 0 0 50%;
            opacity: .6;
        }
    }

    &__address {
        opacity: .6; 
    }
}