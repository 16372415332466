.logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: (1/3 * 100%);
    flex: 1 1 (1/3 * 100%); /*grow | shrink | basis */
    height: 100px;



    img {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20px);
        margin-left: auto;
        margin-right: auto;
        display: block;

        @media (max-width: 840px) {
            width: 20%;
            //margin-right: 10px;

        }

        @media (max-width: 650px) {
            //width: 30%;
            //height: auto;
            display: block;
            right: 30%;
            width: 40%;
        }

        &.is-visible {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
            transition-property: all;
            transition-duration: 300ms;
            transition-timing-function: ease-out;
        }
    }
}


.flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 165px;
    margin-bottom: 165px;
    max-width: none;

    @media(max-width: 1600px){
        //display: block;
        margin-left: 50px;
      }

      @media(min-width: 1601px){
        //display: block;
        margin-left: 75px;
      }

      @media(min-width: 1900px){
        //display: block;
        margin-left: 160px;
      }


      @media(max-width: 840px){
        //display: block;
        margin-left: 0px;
      }
    

    @media(max-width: 816px){
        margin-top: 50px;
        margin-bottom: 50px;
        display: block;
      }
}