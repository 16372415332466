@import '~styles/utils/mixins';
@import '~styles/utils/config';

.header { 
    color: #FFF; 

    &__inner {
        position: fixed;
        top:1em;
        left:0;
        right:0;
        mix-blend-mode: difference;
        z-index: 1000; 
        height: 3em;

        @media (max-width: 500px) {
            top: .5em;
        }
    }

    &__container {  
        @include container;
        position: relative;  
        height: 100%;
    } 

    &__link.is-active {
        color: red;
    }

    &__logo {
        position: absolute; 
        left: 50%;
        top: 50%;
        display: flex;
        font-weight: 800;
        transform: translate(-50%, -50%);
        transition: all cubic-bezier(0.83, 0, 0.17, 1) .85s, opacity .2s;
        white-space: nowrap;
        will-change: left;
        width: 6em;
        border: .5em solid transparent;  

        &__logo {  
            display: block; 
            flex: 0 0;
            flex-basis: 3em;
        } 

        &__arrow { 
            display: block; 
            flex: 0 0;
            flex-basis: .001em;
            transition: all  .6s; 
            margin-left: .25em;
            overflow: hidden;  

            svg {
                width: 1.3em;
                margin-left: -.35em;
                transition: all  .6s; 
            }

            &--full { 
                flex-basis: 1.15em;  
            }

            &--hover {
                flex-basis: 1.5em;

                svg { 
                    margin-left: 0;
                }
            }
        }

        &--is-back { 
            left: 100%; 
            margin-left: -.5em; 
            transform: translate(-100%, -50%);
        } 
    }

    &__navigation {
        display: flex; 
        height: 100%;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        transition: all .2s .25s;

        &--is-hidden { 
            opacity: 0;

            a:first-child {
                transition: transform .1s 1s;
                transform: translateY(-10em);
            }
        }

        a {
            display: block;
            border: .5em solid transparent;
            margin: -.5em; 
        }
        
    } 

    .link--touching {
        opacity: .5;
    }
}
