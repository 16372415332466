@import '~styles/utils/config';

.splitting.lines {

        .word {
            opacity: 0;
            transform: translateY(60px);
            //transition-duration: calc(500ms * var(--line-total));
            @media (max-width: $bp-lg) {
                transform: translateY(20px);
            }
        }

    .is-active {
        .word {
            opacity: 1;
            transform: translateY(0);
            transition-duration: 1200ms;
            transition-property: all;
            transition-timing-function: $transition;
            transition-delay: calc(100ms + (100ms * var(--line-index)));
        }
    }
}