@import '~styles/utils/mixins';
@import '~styles/utils/config';

.cases {
    &__title {
        margin-bottom: $padding-lg;
        max-width: 800px;
    }
}

.case {
    display: flex; 
    //height: 100vh;

    @media (max-width: 1300px) {
        //height: 80vh; 
    }

    @media (max-width: 750px) {
        height: auto;
        display: block;
        flex-wrap: wrap;
    }

    &--gold {
        background-color: rgb(222,226,226);
    }
    
    &--green {
        background-color: rgb(168,212,207);
    }

    &--light-green {
        background-color:  rgb(253,253,226);
    }

    &__media {
        flex: 1; 

        img, video {
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(#000, .1);
            object-fit: cover;
        }

        @media (max-width: 750px) {
            flex: 0 0 100%; 
            height: 15em;
        }
    }

    &__info { 
        padding: 3em;
        padding-right: 10vw;
        display: flex;
        flex: 0 0 50%;
        flex-direction: column; 

        @media (max-width: 1300px) {
            flex-basis: 65%; 
        }
 
        @media (max-width: 750px) {
            @include container();
            padding: 3em 0; 
            display: block;
            box-sizing: border-box;
        }

        @media (max-width: 500px) {
            padding-top: 2em;
            padding-bottom: 2em;
        }
    }

    &__caption {
        margin-top: auto;
    }

    &__title {
        margin-top: .25em;
        margin-bottom: auto;
        padding-bottom: .75em;
    }

    &__text {
        // empty
    }

    &__tags {
        margin-top: 3em;
        display: flex;
        text-transform: uppercase;

        @media (max-width: 320px) {
            display: block;
            margin-top: 2em;

            li  { 
                &::before {
                    content: "—"
                }
            }
        }   

        li:not(:first-child) {
            &::before {
                content: "—"
            }
        }
    }
}