@import '~styles/utils/mixins';
@import '~styles/utils/config';

.two-column-section {  
    @include container;

    &__title { 
        flex-basis: 4/12 * 100%;
        margin-bottom: .3em;

        @media (max-width: 850px) {
            flex: 0 0 100%;
            margin-bottom: .75rem;
        }
    }

    &__intro {
        max-width: 40em;
        margin-bottom: 3em;

        p+p {
            margin-top: .5em;
        }

        @media (max-width: 850px) { 
            margin-bottom: 2em;
        }
    }

    &__items {  
        display: flex;
        flex-wrap: wrap; 
        margin-right: -1.5em; 
        margin-bottom: -3em; 

        p +p {
            margin-top: .5em;
        }

        @media (max-width: 1050px) {  
            // margin-left: -1.5em;
            margin-right: 0;

        }

        @media (max-width: 850px) { 
            margin-bottom: -2em; 
            margin-left: 0;
        }
    }

    &__item {
        box-sizing: padding-box;
        margin-bottom: 3em;  

        @media (max-width: 1200px) {
            flex: 0 0 100%;
        }

        @media (max-width: 850px) { 
            margin-bottom: 1.5em; 
            padding: 0;
        }

        @media (max-width: 675px) {
            flex: 0 0 100%;
        }
    }

    &__item-list {
        margin-top: 1em;
        color: $color-dark-grey;
    }

    &__item-container {
        margin-bottom: 1.5em; 
        @media (min-width: 675px) {
            display: flex;
        }
    }

    &__item-title {
        @media (min-width: 675px) {
            flex: 0 0 40%;
        }
    }
}