
.studio-life {
    
    &__title {
        margin-bottom: 1.5em;

        svg {
            display: inline-block;
            width: .85em;
            margin-right: .35em;
            transform: rotate(90deg);
        }
    }

    &__images {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
       

        &__image {
            flex: 0 0 41%;

            // @media (max-width: 1100px) {
            //     flex-basis: 50%;
                
            //     &:nth-child(3),
            //     &:nth-child(6),
            //     &:nth-child(7) {
            //         display: none;
            //     }
            // }

            @media (max-width: 400px) {
                flex-basis: 100%;
            } 

            &:nth-child(7) {
                flex-basis: 100%;
            }

            .a {
                 width: 50%; 
                 margin-top: 5em;

                @media (max-width: 600px) {
                    width: 100%;
                } 
            }

            .b {
                 width: 80%; 
                 margin-top: -6em;

                 @media (max-width: 600px) {
                    width: 100%;
                } 

            }
            .c {
                width: 80%;
                float: right;
                margin-right: -32em;
                margin-top: -5em;
                @media (max-width: 600px) {
                    width: 100%;
                } 

                @media (max-width: 1427px) {
                    margin-right: -25em;
                } 

                @media (max-width: 1000px) {
                    margin-right: -15em;
                } 
                 @media (max-width: 538px) {
                     margin-right: -15em;
                 } 
                 @media (max-width: 538px) {
                    margin-right: -12em;
                } 
                @media (max-width: 400px) {
                    margin-right: 0em;
                    margin-top: -2em;
                } 


            }

            .e {
                width: 90%;
                // margin-left: 0;
                // margin-bottom: 1em;
                // margin-right: auto;
                 margin-top: 4em;
                 position: absolute;
                 bottom: 0;

                 @media (max-width: 800px) {
                    padding-bottom: 15px;
                } 
            }

            .d {
                width: 100%; 
                 //margin-top: -5em;
                // margin-left: auto;
                // margin-right: auto;
            
                @media (max-width: 800px) {
                    padding-bottom: 15px;
                } 

            }

            .f {
                 width: 50%;
                // //float: right;
                 width: 200px !important;
                 height: 248px !important;
                margin-top: 4em;
                @media (max-width: 500px) {
                    margin-top: 0em;
                } 
                
            }

        }
    }
}

.g {
    width: 60%; 
    margin-left: auto;
    margin-right: auto; 
    margin-top: 1em;
}
