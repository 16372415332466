
@media print {
    * {
        position: static !important;
        color: black !important;
        display: inherit !important;
        background: transparent !important;
        mix-blend-mode: normal !important;
        overflow: visible !important;
        opacity: 1 !important;
        height: auto  !important;
        min-height: 0 !important;
        width: auto !important;
        margin: 0 !important;
        padding: 0 !important;
        transform: none !important;

        &::after,
        &::before {
            display: none !important;
        }
    }
 
    span, em, bold, a, button {
        display: inline-block !important;
    }

    video, img, svg, .image, .header, [aria-hidden],  style, script  {
        display: none !important; 
    } 

    
    .clients__list__item img,  .clients__list__item {
        display: inline-block !important;
    }

    .h1 {
        font-size: 50px !important;
        margin-bottom: .25em !important;
        margin-top: 1.5em !important; 
    }
    .h1b {
        font-size: 40px !important;
        margin-bottom: 1em !important;
        margin-top: 2em !important; 
    }
    .h2 {
        font-size: 20px;
        margin-bottom: 1em !important;
        margin-top: 2em !important; 
    } 
    .h3 {
        font-size: 32px;
        margin-bottom: .5em !important;
        margin-top: 1em !important;
    } 

    p, ul {
        margin-top: 1em !important;
        margin-bottom: 1em !important;
    } 

    ul > li { 
        margin-bottom: .25em !important;
    }

    h1, h2, h3, h4, h5 {
        break-after: avoid-page !important;
        break-before: avoid-page !important;
        break-inside: avoid-page !important;
    }

    p { 
        break-inside: avoid-page !important;
    }
}