.spacing {

    @media (min-width: 840px){
        margin-top: 2em;


    }
    @media (max-width: 840px){

        margin-top: 25em;


    }
    @media (max-width: 570px){

        margin-top: 20em;


    }


    @media (max-width: 450px){

        margin-top: 15em;


    }

}