// @import "../../../assets/style/mixins.scss";

.privacy-policy {
    h2 {
        line-height: 1;
        letter-spacing: 0;
        margin-bottom: .45em;
        font-size: 40px;
    }

    h3 {
        font-size:unquote('min(max(32px, 3vw), 60px)'); // 60/24 + em;
        line-height: 1;
        font-weight: 400;
        letter-spacing: -.02em;
    }

    h2, h3 {
        margin-top: 1.5rem;
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }
    }

    address {
        margin-left: 2rem;
    }

    ol {
        list-style: auto;
        margin-left: 1rem;
        padding-left: .5em;
    }

    p, address {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        strong, b {
            font-weight: bold;
        }
    }
}
