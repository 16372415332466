@import '~styles/utils/mixins';
@import '~styles/utils/config';
 

.hero {
    &__video {
        width: 100%;
        height: 100vh;
        object-fit: cover;
        margin-bottom: 2em;
        filter: brightness(0.5);
    }
}

.hero-text { 
    --bottom: 200px;
    position: fixed; 
    bottom: var(--bottom); 
    left: 0; 
    right: 0;
    color: #FFF;
    z-index: 1111;
    mix-blend-mode: difference;

    &--fake {
         display: none;
    }

    &--ghost {
        opacity: 0; 
        position: static;
        color: inherit;
        mix-blend-mode: normal;
    }

    &--real { 
        opacity: 1;  
    } 
}
.hero-subtext {
    margin-top: 1em;

    p {
        max-width: 38em;
        margin-top: 1em;
    }
}
.grey-color{
    color: #808080;
}

.row {
  display: flex;

  @media(max-width: 800px){

    padding-left: max(0px);
    padding-left: 0px;
    padding-right: 0px;
    display: block;
}

.distance1 {

  padding-right: max(12px);
  @media(max-width: 520px){

    padding-right: max(0px);

}
}

.distance2 {

  padding-left: max(12px);
  @media(max-width: 800px){

    padding-left: max(0px);

}
}
}

.dot {
    color: #fa0610;
}

.fade-in {
    animation: fadeIn ease 3s;
    -webkit-animation: fadeIn ease 3s;
    -moz-animation: fadeIn ease 3s;
    -o-animation: fadeIn ease 3s;
    -ms-animation: fadeIn ease 3s;
  }
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
