.block {
    margin-top: 7em;
    margin-bottom: 7em; 

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    @media (max-width: 1200px) { 
        margin-top: 6em;
        margin-bottom: 6em;
    }

    @media (max-width: 1100px) { 
        margin-top: 3.5em;
        margin-bottom: 3.5em;
    }

    @media (max-width: 700px) { 
        margin-top: 3em;
        margin-bottom: 3em;
    }

    @media (max-width: 550px) { 
        margin-top: 2.25em;
        margin-bottom: 2.25em;
    }

    &--tight { 
        margin-top: 4em;
        margin-bottom: 4em; 

        @media (max-width: 1300px) { 
            margin-top: 2.5em;
            margin-bottom: 2.5em;
        }

        @media (max-width: 700px) { 
            margin-top: 2em;
            margin-bottom: 2em;
        }

        @media (max-width: 550px) { 
            margin-top: 2em;
            margin-bottom: 2em;
        }
    } 
}