.cursor {
    position: fixed;
    top:px;
    left:0px;
    width: .75em;
    height: .75em; 
    border: 2px solid black;
    pointer-events: none;
    z-index: 2147483647; // more than the cookie banner
    transform: translate(-100px, -100px);
    transition: width .25s, height .25s;
    border-radius: 50%;

    @supports (mix-blend-mode: difference) {
        mix-blend-mode: difference;
        border-color: #FFF;
    }
} 

body:not(.has-error) * {
    cursor: none;
} 