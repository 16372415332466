@import '~styles/utils/mixins';

.container {
    @include container;

    &--flex {
        display: flex;


        @media (max-width: 840px) {
            display: block;

            p{
                margin-bottom: 15px;
                
            }
        } 
    }
}
