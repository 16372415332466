@import '~styles/variables'; 

.image-section {    
    &__img {
        width: 9/12 * 100%;
        margin-left: 3 /12 * 100%; 

        @media (max-width: 650px) {
            width: 100%;
            margin-left:0;
        }
    } 
}