@mixin fit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

@mixin responsive-font($min-size: $font-size-min, $size: $font-size, $limit: $page-limit) {
    $scale-font-enable: global-variable-exists(scale);

    $unitless-min: strip-unit($min-size);
    $unitless-size: strip-unit($size);
    $baseline: strip-unit($min-mobile);
    $font-multiplier: (($unitless-size - $unitless-min) / (strip-unit($limit) - $baseline));
    $font-baseline: ($unitless-min - $font-multiplier * $baseline);

    @if $unitless-min >= $unitless-size {
        @warn 'Responsive font: min-size equal or greater than size';
    }

    font-size: #{$unitless-min}px;

    @if $scale-font-enable == true {
        // stylelint-disable-next-line
        font-size: calc(#{$unitless-min}px * #{var(--scale-font)});
    }

    @media (min-width: $min-mobile) {
        font-size: #{$unitless-min}px;

        @if $scale-font-enable == true {
            // stylelint-disable-next-line
            font-size: calc((#{$font-multiplier} * #{100vw} + (#{$font-baseline}px)) * #{var(--scale-font)});
        } @else {
            // stylelint-disable-next-line
            font-size: calc((#{$font-multiplier} * #{100vw} + (#{$font-baseline}px)));
        }
    }

    @media (min-width: $limit) {
        font-size: #{$unitless-size}px;

        @if $scale-font-enable == true {
            // stylelint-disable-next-line
            font-size: calc(#{$unitless-size}px * #{var(--scale-font)});
        }
    }
}

// Alias for responsive-font mixin
@mixin rf($args...) {
    @include responsive-font($args...);
}

@mixin vertically-responsive($property, $number) {
    #{$property}: $number;

    @if global-variable-exists(scale) {
        @supports (--css: variables) {
            #{$property}: calc(#{$number} * var(--scale-element));
        }
    }
}

// Segment customisable per-component / instance rather than being forced to work around defaults.
@mixin segment(
    $padding-top: $segment-padding,
    $padding-bottom: $segment-padding,
    $padding-top-mobile: $segment-padding-mobile,
    $padding-bottom-mobile: $segment-padding-mobile
) {
    flex-grow: 1;

    // Set up unitless values
    $padding-top-mobile-unitless: strip-unit($padding-top-mobile);
    $padding-bottom-mobile-unitless: strip-unit($padding-bottom-mobile);
    $padding-top-unitless: strip-unit($padding-top);
    $padding-bottom-unitless: strip-unit($padding-bottom);

    @include vertically-responsive(padding-top, $padding-top-mobile-unitless * 1px);
    @include vertically-responsive(padding-bottom, $padding-bottom-mobile-unitless * 1px);

    @media (min-width: $min-tablet) {
        @include vertically-responsive(padding-top, percentage(($padding-top-unitless * 1px) / $page-limit));
        @include vertically-responsive(padding-bottom, percentage(($padding-bottom-unitless * 1px) / $page-limit));
    }

    @media (min-width: $page-limit) {
        @include vertically-responsive(padding-top, $padding-top-unitless * 1px);
        @include vertically-responsive(padding-bottom, $padding-bottom-unitless * 1px);
    }
}

@mixin container() { 
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: (1700px + 64px * 2)) {
        margin-left: 64px;
        margin-right: 64px;
    }

    @media (max-width: (1000px)) {
        margin-left: 32px;
        margin-right: 32px;
    }

    @media (max-width: (800px)) {
        margin-left: 28px;
        margin-right: 28px;
    }
    
    @media (max-width: (600px)) {
        margin-left: 20px;
        margin-right: 20px;
    } 
}

@mixin js-only {
    html:not(:global(.no-js)) & {
        @content;
    }
}

@mixin no-js {
    html:not(:global(.js)) & {
        @content;
    }
}

@mixin retina {
    @media only screen and (-webkit-min-device-pixel-ratio: 1.3),
        only screen and (-o-min-device-pixel-ratio: 13/10),
        only screen and (min-resolution: 120dpi) {
        @content;
    }
}
