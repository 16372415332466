@import '~styles/utils/config';

.footer {

    @media (min-width: $bp-md) {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        min-height: 200px;
        padding: $padding-sm;
        @media (min-width: $bp-md) {
            width: 50%;
            display: flex;
            flex-direction: column;
            height: 60vh;
            height: calc(60vh - #{$padding-xlg * 3});
            padding: $padding-xlg;
        }
        @media (min-width: $bp-lg) {
            height: 100vh;
            height: calc(100vh - #{ $padding-xlg * 3});
        }

        &:nth-child(2) {
            background: $color-dark-red;
            color: $color-white;
        }

        &-privacy-policy {
            width: 100%;
            padding: $padding-sm;
            text-align: right;

            @media (min-width: $bp-md) {
                padding: $padding-sm $padding-xlg;
                background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, $color-dark-red 50%, $color-dark-red 100%);
            }

            font-size: .8rem;
        }
    }

    &__title {
        padding: $padding-sm 0;
        @media (min-width: $bp-lg) {
            padding: $padding-lg 0;
        }
        @media (min-width: $bp-lg) {
            padding: $padding-xlg 0;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        &.left {
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
    }

    &__arrow {
        display: flex;
        width: 40px;
        order: 1;
        margin-left: 20px;
        svg {
            width: 100%;
        }

        .left & {
            transform: scaleX(-1);
            margin-right: 20px;
            margin-left: 0;
        }
    }

}
